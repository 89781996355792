// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-books-js": () => import("./../../../src/pages/books.js" /* webpackChunkName: "component---src-pages-books-js" */),
  "component---src-pages-films-js": () => import("./../../../src/pages/films.js" /* webpackChunkName: "component---src-pages-films-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-series-js": () => import("./../../../src/pages/series.js" /* webpackChunkName: "component---src-pages-series-js" */),
  "component---src-pages-styleguide-js": () => import("./../../../src/pages/styleguide.js" /* webpackChunkName: "component---src-pages-styleguide-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */),
  "component---src-templates-book-js": () => import("./../../../src/templates/book.js" /* webpackChunkName: "component---src-templates-book-js" */),
  "component---src-templates-film-js": () => import("./../../../src/templates/film.js" /* webpackChunkName: "component---src-templates-film-js" */),
  "component---src-templates-show-js": () => import("./../../../src/templates/show.js" /* webpackChunkName: "component---src-templates-show-js" */)
}

